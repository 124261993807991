export const APP_CONFIG = {
  debug: true,
  apiUrl: process.env.API_URL,
  imgUrl: process.env.IMAGE_URL,
  appUrl: process.env.APP_URL,
  profileKey: process.env.USER_PROFILE_KEY,
  tokenKey: process.env.TOKEN_KEY,
  firebaseApiKey: process.env.FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: process.env.FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: process.env.FIREBASE_APP_ID,
  firebaseMeasurementId: process.env.FIREBASE_MEASUREMENT_ID,
  vapidKey: process.env.FIREBASE_VAPID_KEY,
};
