import { createSlice } from '@reduxjs/toolkit';
import { paramsNotify } from '../common/constants';
import { paramsNotifyModelProps } from '../common/interface';

const initialState: {
  pagingNotification: any;
  isCloseSidebar: boolean;
  languageInput: string;
  listNotification: any;
  paramNotification: paramsNotifyModelProps;
} = {
  pagingNotification: {},
  isCloseSidebar: false,
  languageInput: 'vi',
  listNotification: [],
  paramNotification: paramsNotify,
};
const commonSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    collapseSidebar(state) {
      state.isCloseSidebar = !state.isCloseSidebar;
    },
    changeLanguageInput(state, action) {
      state.languageInput = action.payload;
    },
    setDataNotification(state, action) {
      if (action.payload.list) {
        state.listNotification =
          action.payload.paging.pageIndex === 1
            ? [...action.payload.list]
            : [...state.listNotification, ...action.payload.list];
      }
      if (action.payload.paging && action.payload.paging.pageIndex < 2) {
        state.pagingNotification = action.payload.paging;
      }
    },
    setParamNotification(state, action) {
      if (action.payload.setPage && state?.pagingNotification?.totalPage >= state?.paramNotification?.pageIndex) {
        state.paramNotification = { ...state.paramNotification, pageIndex: state?.paramNotification?.pageIndex + 1 };
      }
    },
  },
});

export const { collapseSidebar, changeLanguageInput, setParamNotification, setDataNotification } = commonSlice.actions;

export default commonSlice.reducer;
