import { createSlice } from '@reduxjs/toolkit';

export interface ParamsFilterModel {
  Keyword?: string;
  pageIndex?: number | string;
  pageSize?: number | string;
  sort?: string;
  direction?: string;
  tabKey?: string;
  StatusId?: number;
  CreateAt?: string;
  CreateStart?: string;
  CreateEnd?: string;
  dateFrom?: string;
  dateTo?: string;
  type?: undefined | number;
  [name: string]: any;
}

export const initParamsFilterDebt = {
  dateFrom: '',
  dateTo: '',
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

const initialState: { paramsFilter: ParamsFilterModel } = {
  paramsFilter: initParamsFilterDebt,
};

const debtSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterDebt;
    },
  },
});

export const { setParamFilter, resetParamFilter } = debtSlice.actions;

export default debtSlice.reducer;
