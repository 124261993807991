import { createSlice } from '@reduxjs/toolkit';
import { ParamFilterBusinessSupportModel } from '../model/BusinessSupportModel';

export const initParamsFilteBusinessSupport: Partial<ParamFilterBusinessSupportModel> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 5,
  IsClinic: true,
};

const BusinessSupportSlice = createSlice({
  name: 'businessSupport',
  initialState: {
    paramsFilter: initParamsFilteBusinessSupport,
    businessSupportSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilteBusinessSupport;
    },
    setRoleSelectedRow(state, action) {
      state.businessSupportSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = BusinessSupportSlice.actions;

export default BusinessSupportSlice.reducer;
