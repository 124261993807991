import { createSlice } from '@reduxjs/toolkit';
import { initParamsFilterCoopera } from '../store/constants';

const BusinessCooperaSlice = createSlice({
  name: 'businessCoopera',
  initialState: {
    paramsFilter: initParamsFilterCoopera,
    businessCooperaSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterCoopera;
    },
    setRoleSelectedRow(state, action) {
      state.businessCooperaSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = BusinessCooperaSlice.actions;

export default BusinessCooperaSlice.reducer;
