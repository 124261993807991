import { createSlice } from '@reduxjs/toolkit';
import { initParamsDefault } from '../store/constants';

const businessInfoSlice = createSlice({
  name: 'BusinessInfo',
  initialState: {
    paramsFilter: initParamsDefault,
    businessInfoSelectedRows: [],
    businessDetail: {},
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    setDataDetailBusiness(state, action) {
      state.businessDetail = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsDefault;
    },
    setBusinessInfoSelectedRow(state, action) {
      state.businessInfoSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setBusinessInfoSelectedRow, setDataDetailBusiness } =
  businessInfoSlice.actions;

export default businessInfoSlice.reducer;
