import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterTransfer: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 5,
};

const TransferSlice = createSlice({
  name: 'transfer',
  initialState: {
    paramsFilter: initParamsFilterTransfer,
    transferSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterTransfer;
    },
    setRoleSelectedRow(state, action) {
      state.transferSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = TransferSlice.actions;

export default TransferSlice.reducer;
