import { ACTION_CRUD } from 'store/common/constants';

export const initActionModal = { id: 0, type: ACTION_CRUD.NONE, index: -1 };

export const paddingDefault = {
  current_page: 1,
  from: 1,
  last_page: 0,
  links: [],
  path: '',
  per_page: 10,
  to: 1,
  total: 10,
};

export enum ROLE {
  BUSINESS = 4,
  USER = 3,
}

export enum STATUS {
  ALL = 2,
  ACTIVE = 1,
  DEACTIVE = 0,
}
