import { ACTION_CRUD } from 'store/common/constants';

export const initActionModal = { id: 0, type: ACTION_CRUD.NONE, index: -1 };

export const initParamsFilter = {
  statusOrder: 4,
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
  tab: 1,
};

export const initParamsFilterUser = {
  statusUser: 4,
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

export const pagingDefault = {
  current_page: 1,
  from: 1,
  last_page: 0,
  links: [],
  path: '',
  per_page: 10,
  to: 1,
  total: 10,
};
