import { createSlice } from '@reduxjs/toolkit';
import { initParamsFilterUser } from './constants';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    actionView: false,
    selectChange: [],
    paramsFilter: initParamsFilterUser,
  },
  reducers: {
    setActionView(state, action) {
      state.actionView = action.payload;
    },
    setSelectChange(state, action) {
      state.selectChange = action.payload;
    },
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterUser;
    },
  },
});

export const { setActionView, setSelectChange, setParamFilter, resetParamFilter } = userSlice.actions;
export default userSlice.reducer;
