import { createSlice } from '@reduxjs/toolkit';
import { initParamsFilter } from './constants';

const orderSlide = createSlice({
  name: 'order',
  initialState: {
    actionView: false,
    paramsFilter: initParamsFilter,
  },
  reducers: {
    setActionView(state, action) {
      state.actionView = action.payload;
    },
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilter;
    },
  },
});

export const { setActionView, setParamFilter, resetParamFilter } = orderSlide.actions;
export default orderSlide.reducer;
