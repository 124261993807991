import { ACTION_CRUD } from 'store/common/constants';

export const initActionModal = { id: 0, type: ACTION_CRUD.NONE, index: -1 };

export const initParamsDefault = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

export const initParamsFilter = {
  IsClinic: true,
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};

export const pagingDefault = {
  current_page: 1,
  from: 1,
  last_page: 0,
  links: [],
  path: '',
  per_page: 10,
  to: 1,
  total: 10,
};

export const initParamsFilterPackage = {
  keyword: '',
  pageIndex: 1,
  pageSize: 5,
};

export const initParamsFilterCoopera = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
};
