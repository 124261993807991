import { createSlice } from '@reduxjs/toolkit';
import { initParamsFilter } from '../store/constants';

const SlideSlice = createSlice({
  name: 'slide',
  initialState: {
    paramsFilter: initParamsFilter,
    slideSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      console.log('action: ', action.payload);

      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilter;
    },
    setRoleSelectedRow(state, action) {
      state.slideSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = SlideSlice.actions;

export default SlideSlice.reducer;
