import { createSlice } from '@reduxjs/toolkit';
import { ParamFilterDashboardModal } from '../model/dashboardModel';

export const initParamsFilterDashboard: Partial<ParamFilterDashboardModal> = {
  year: 2022,
  type: 1,
  listYear: '',
  provinceId: 0,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    paramsFilter: initParamsFilterDashboard,
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterDashboard;
    },
  },
});

export const { setParamFilter, resetParamFilter } = dashboardSlice.actions;

export default dashboardSlice.reducer;
