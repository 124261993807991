import { createSlice } from '@reduxjs/toolkit';
import { ParamsFilterModelDefault } from 'store/common/interface';

export const initParamsFilterFaqs: Partial<ParamsFilterModelDefault> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 5,
};

const FaqsSlice = createSlice({
  name: 'faqs',
  initialState: {
    paramsFilter: initParamsFilterFaqs,
    faqsSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilterFaqs;
    },
    setRoleSelectedRow(state, action) {
      state.faqsSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = FaqsSlice.actions;

export default FaqsSlice.reducer;
