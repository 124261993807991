import { createSlice } from '@reduxjs/toolkit';
import { ParamFilterRoleModal } from '../model/RolesModel';
import { ROLE, STATUS } from './constants';

export const initParamsFilterRole: Partial<ParamFilterRoleModal> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 5,
  status: STATUS.ALL,
  role: ROLE.BUSINESS,
};

const roleSlice = createSlice({
  name: 'role',
  initialState: {
    paramsFilterRole: initParamsFilterRole,
    roleSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilterRole = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilterRole = initParamsFilterRole;
    },
    setRoleSelectedRow(state, action) {
      state.roleSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = roleSlice.actions;

export default roleSlice.reducer;
