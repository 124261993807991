import { createSlice } from '@reduxjs/toolkit';
import { ParamFilterUserSupportModel } from '../model/UserSupportModel';

export const initParamsFilteUserSupport: Partial<ParamFilterUserSupportModel> = {
  keyword: '',
  pageIndex: 1,
  pageSize: 5,
  IsClinic: false,
};

const UserSupportSlice = createSlice({
  name: 'userSupport',
  initialState: {
    paramsFilter: initParamsFilteUserSupport,
    userSupportSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilteUserSupport;
    },
    setRoleSelectedRow(state, action) {
      state.userSupportSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = UserSupportSlice.actions;

export default UserSupportSlice.reducer;
