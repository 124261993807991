import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { apiStore } from './storeApi';

import common from './common/commonSlice';
import auth from 'pages/auth/store/AuthSlice';
import user from '../pages/user/store/userSlide';
import role from 'pages/roles/store/roleSlice';
import faqs from 'pages/app-info/store/FaqsSlice';
import transfer from 'pages/app-info/store/TransferSlice';
import slide from 'pages/app-info/store/SlideSlice';
import field from 'pages/app-info/store/FieldSlice';
import debtSlice from 'pages/business/store/debtSlice';
import businessInfo from 'pages/business/store/businessInfoSlice';
import businessSupport from 'pages/business/store/BusinessSupportSlice';
import businessCoopera from 'pages/business/store/BusinessCooperaSlice';
import businessManagement from 'pages/business/store/businessManagementSlice';
import userSupport from 'pages/user/store/UserSupportSlice';
import order from 'pages/user/store/orderSlide';
import dashboard from 'pages/dashboard/store/dashboardSlice';


const rootReducer = combineReducers({
  [apiStore.reducerPath]: apiStore.reducer,
  common,
  auth,
  transfer,
  role,
  user,
  faqs,
  businessInfo,
  businessSupport,
  userSupport,
  order,
  businessCoopera,
  businessManagement,
  slide,
  field,
  dashboard,
  debtSlice
});
export default rootReducer;

const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [...getDefaultMiddleware()];
  return middlewareList;
};
//API slice Will include automatically generated redux reducer And a custom middleware
export const rootStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware).concat(apiStore.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootStore.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof rootStore.dispatch;

setupListeners(rootStore.dispatch);
// export default rootReducer;
