import { createSlice } from '@reduxjs/toolkit';
import { initParamsFilter } from '../store/constants';

const FieldSlice = createSlice({
  name: 'field',
  initialState: {
    paramsFilter: initParamsFilter,
    fieldSelectedRows: [],
  },
  reducers: {
    setParamFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    resetParamFilter(state) {
      state.paramsFilter = initParamsFilter;
    },
    setRoleSelectedRow(state, action) {
      state.fieldSelectedRows = action.payload;
    },
  },
});

export const { setParamFilter, resetParamFilter, setRoleSelectedRow } = FieldSlice.actions;

export default FieldSlice.reducer;
