import { ACTION_CRUD } from 'store/common/constants';
import { DetailFaqs } from '../model/FaqsModel';
import { DataPolicy } from '../model/PrivacyPolicyModel';
import { DataTermsAndConditions } from '../model/TermsAndConditionsModel';

export const initActionModal = { id: 0, type: ACTION_CRUD.NONE, index: -1 };
export const initParamsFilter = {
  pageIndex: 1,
  pageSize: 5,
};

export const pagingDefaultBanner = {
  current_page: 1,
  from: 1,
  last_page: 0,
  links: [],
  path: '',
  per_page: 5,
  to: 1,
  total: 10,
};

export const pagingDefaultTransfer = {
  current_page: 1,
  from: 1,
  last_page: 0,
  links: [],
  path: '',
  per_page: 5,
  to: 1,
  total: 10,
};

export const initdata: DetailFaqs = {
  questionId: 0,
  title: null,
  titleObj: {
    vi: null,
    en: null,
  },
  answer: null,
  answerObj: {
    vi: null,
    en: null,
  },
};

export const initDataTerms: DataTermsAndConditions = {
  title: null,
  description: null,
  settingId: 1,
};

export const initDataPlicy: DataPolicy = {
  title: '',
  description: '',
  settingId: 2,
};

export enum STATUS_SETTING_ID {
  TERMS = 1,
  POLICY = 2,
}

export enum INDEX_ITEM_CHANGE {
  UP = 0,
  DOWN = 1,
}

export const PayloadBank = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
  sort: '',
  direction: '',
};
