import { FormProps } from 'antd/lib/form';
import { TablePaginationConfig } from 'antd/lib/table';

export const KEY_API_FAIL = 'Error';
export const KEY = {
  SAVE: 'save',
  CLOSE: 'close',
  EDIT: 'edit',
  ADD: 'add',
  DETAIL: 'detail',
  DELETE: 'delete',
  SUCCESS: 'success',
  ERROR: 'error',
  FAILED: 'failed',
  VI: 'vi',
  EN: 'en',
};

export const DEFAULT_PAGING = { pageIndex: 1, pageSize: 10, totalPage: 1, totalRecords: 12 };

export enum ACTION_CRUD {
  ADD,
  EDIT,
  EDIT_BUSINESS_SP,
  EDIT_USER_SP,
  VIEW,
  NONE,
  VIEW_HISTORY,
  VERIFIED,
  DELETE,
  VIEW_PAYMENT_CONFIRM,
  PAUSE,
  CHANGE_PASSWORD,
}

const typeTemplate = '${label} không đúng định dạng';

export const validateMessagesForm = {
  required: '${label} không được bỏ trống',
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
};

export const formDefaultLayout: FormProps = {
  colon: false,
  layout: 'vertical',
  wrapperCol: { span: 24 },
  validateMessages: validateMessagesForm,
  className: 'font-weight-bold',
};

export const initActionModal = { id: -1, type: ACTION_CRUD.NONE, index: -1 };

export const DEFAULT_PAGINATION = {
  per_page: 10,
  page: 1,
};

export const paginationDefaultProps: TablePaginationConfig = {
  pageSizeOptions: ['5', '10', '20', '30', '40'],
  position: ['bottomCenter'],
  showSizeChanger: true,
  showQuickJumper: true,
};

export const listMonthName = ['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12'];

export const listQuarter = ['1', '2', '3', '4'];
export enum StatusUploadFile {
  SUCCESS,
  FAILURE,
  PROCESS,
}
export enum TYPE_APPROVAL {
  REJECT = 0,
  APPROVAL = 1,
}

export enum TYPE_ACTIVED {
  ACTIVE = 0,
  PAUSE = 1,
}

export enum TYPE_TIME {
  MONTH = 1,
  QUARTER = 2,
  YEAR = 3,
}

export const TYPE_NOTIFICATION = {
  CLINIC: 'CLINIC',
  UPDATECLINIC: 'UPDATECLINIC',
  ORDER: 'ORDER',
  USER: 'USER',
};
export const initImage = './../../../assets/images/img-place-holder.jpg';

export const paramsNotify = {
  keyword: '',
  pageIndex: 1,
  pageSize: 10,
  sort: '',
  direction: '',
};
